.FileUploadBlock {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 8px;
}

.FileUploadInput {
  display: none;
}
