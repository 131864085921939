.Details {
  display: flex;
  flex-wrap: wrap;
}

.Detail {
  padding: 8px;
  flex-grow: 1;
  width: 25%;
  color: #252c2e;
  font-size: 15px;
  line-height: 24px;
  text-transform: capitalize;
}

.RowInput {
  width: 100%;
}

.ActionsBlock {
  display: flex;
  gap: 12px;
}

.LoaderBlock {
  height: 50vh;
  width: calc(100vw - 30px);
  display: flex;
  align-items: center;
  justify-content: center;
}
