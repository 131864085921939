.Header {
  width: 100%;
}

.Tabs {
  flex-basis: 100%;
}


.TabBlocks {
  max-width: 100%;
  width: 100%;
}
