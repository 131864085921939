.FormBody {
  padding: 24px;
}

.RequiredFields {
  display: flex;
  align-items: center;
}

.OptionalFields {
  margin-top: 10px;
  display: flex;
  gap: 8px;
}
