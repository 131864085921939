.Header {
    margin-bottom: 16px;
}

.HeaderTitle {
    margin-right: 32px;
}

.HeaderSection {
    gap: 16px;
}

.Table {
    min-width: 50%;
}

.Body {
    display: grid;
    grid-template-columns: 2fr minmax(470px, 1fr);
    gap: 16px;
}
