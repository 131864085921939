.ActionItem {
  display: inline-flex;
  width: fit-content;
  margin-right: 10px;
}

.ActionLoaderItem {
  margin-right: 10px !important;
  width: 60px;
}
