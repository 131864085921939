.Container {
    display: flex;
    margin-top: 8px;
    height: 100%;
}

.Container > .Setup {
    margin-right: 8px;
    overflow-y: scroll;
    height: 600px;
}

.Field {
    margin-top: 8px;
}

.Transactions {
    flex: 1;
}

.ScriptSelectList {
    height: 100% !important;
}

.PanelGroup {
    grid-template-columns: 180px 1fr;
    height: 100%;
}

.Buttons {
    display: flex;
    gap: 16px;
}

.WarningPanel {
    height: 60px;
    transition: height 0.3s;
    overflow: hidden;
}

.WarningPanelFull {
    height: 0;
    transition: height 0.3s;
}
